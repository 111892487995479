<template>
  <div>
    <div class="service-chat" @click="onClickIcon">
      <div class="service-chat__icon"></div>
    </div>
    <Chat v-if="firstLoading > 0" v-show="showDialog" :visible.sync="showDialog"/>
  </div>
</template>

<script>
import Chat from './chat.vue'
export default {
  name: 'ChatService',
  components: {Chat},
  data(){
    return{
      showDialog: false,
      firstLoading: 0,
    }
  },
  methods: {
    onClickIcon(){
      this.showDialog = !this.showDialog
      if(this.showDialog && this.firstLoading == 0){
        this.firstLoading ++
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.service-chat{
  position: fixed;
  right: 20px;
  bottom: 30px;
  z-index: 50;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #409EFF;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover{
    cursor:pointer;
  }
  &:active{
    opacity: .8;
  }
  &__icon{
    width: 26px;
    height: 26px;
    background-image: url('~@/assets/serve_icon.png');
    background-repeat: no-repeat;
    background-size: 100%;
  }
}
</style>