
class WebsocketTask{
  constructor(wsUrl, time = 500){
    if(!wsUrl) return new Error('wsUrl is undefined') 
    this.wsUrl = wsUrl   // ws地址

    this.socket = null  // WebSocket实例
    this.isOpenSocket = false  //是否打开websocket
    //测试心跳
    this.data = //测试心跳数据
    this.timeout = time 
    this.heartbeat = null  //测试心跳定时器
    
    this.globalCallback = null  //接收消息回调
    // this.isClosed = false  // 是否用户需要,调用websocket.close关闭
    try{
      return this.initWebsocket()
    }catch(e){
      this.isOpenSocket = false
      this.reconnect()
    }
  }
  //初始化websocket
  initWebsocket(){
    //判断是否有websocket
    if('WebSocket' in window){
      this.socket = new WebSocket(this.wsUrl)
    }else{
      alert('浏览器不支持WebSocket!')
      return false
    }

    this.socket.onopen = (event) => {
      console.log('onopen-websocket链接打开')
      this.heartbeat && clearTimeout(this.heartbeat)
      this.isOpenSocket = true
      this.start()
      this.socket.onmessage = (data) => {
        const messageData = JSON.parse(data.data)
        this.globalCallback && this.globalCallback(messageData)
      }
    }

    this.socket.onclose = (event) => {
      console.log('onclose websocket关闭链接')
      this.isOpenSocket = false
      this.reconnect()
    }

    this.socket.onerror = (event) => {
      console.log('onerror websocket链接错误,请检查!')
    }
  }
  //发送消息
  send(data){
    if(!data) return false
    const messageData = JSON.stringify(data)
    this.socket && this.socket.send(messageData)
  }
  //开始心跳测试
  start(){
    this.heartbeat = setTimeout(() => {
      this.data = {value: 'test-heartbeat'}
      this.send(this.data)
    }, this.timeout)
  }
  //重新链接
  reconnect(){
    clearInterval(this.heartbeat)
    if(!this.isOpenSocket && !this.isClosed){
      this.initWebsocket()
    }
  }
  //获取ws返回的数据
  message(callback){
    this.globalCallback = callback
  }
  //关闭websocket
  close(){
    this.socket && this.socket.close()
    this.socket = null
    this.isClosed = true
  }
}

export default WebsocketTask