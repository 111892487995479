<template>
  <div class="dialogBox">
    <div class="dialogBox_top">
      <div>客服系统</div>
      <div> {{$store.state.user.userInfo.userName}} </div>
      <div 
        @click="onClose" 
        class="dialogBox_top_icon"> 
          <i class="icon-style el-icon-arrow-down" ></i> 
      </div>
    </div>
    <div class="container">
      <div class="name-list"> 
        <nameList :avatar.sync="chatUserAvatar" :list="userList" :itemId.sync="chatUserId"/>
      </div>
      <div class="message-list">
        <messageList :avatar="chatUserAvatar" :itemId.sync="chatUserId" ref="messageList" @message="handleSend"/>
      </div>
    </div>
  </div>
</template>

<script>
import nameList from './nameList.vue'
import messageList from './messageList.vue'
import {chatUserList} from '@/api/chat'
import WebsocketTask from '@/utils/websocket'
export default {
  name: 'chat',
  components: {nameList, messageList},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
      WS_URL:`${process.env.VUE_APP_WS_URL}/api/websocket/websocket?user=`+ this.$store.state.user.userInfo.userId,
      userList: [
        // {
        //   createTime: "2023-03-16 17:40:28",
        //   isRead: 0,
        //   message: "test",
        //   messageId: null,
        //   receiveUserId: null,
        //   sendUserId: null,
        //   userAvatarUrl: "https://tanoshi.oss-cn-hangzhou.aliyuncs.com/coox/portrait/%E9%BB%98%E8%AE%A4%E5%A4%B4%E5%83%8F.jpg",
        //   userId: "1600413120230735874",
        //   userName: "搭档-珠海市",
        // },
        // {
        //   createTime: "2023-03-16 16:27:46",
        //   isRead: 0,
        //   message: "1111",
        //   messageId: null,
        //   receiveUserId: null,
        //   sendUserId: null,
        //   userAvatarUrl: "https://tanoshi.oss-cn-hangzhou.aliyuncs.com/coox/head/2023/03/06/1678094764016.jpg",
        //   userId: "1616311742502907905",
        //   userName: "好莱坞男导演",
        // },
        // {
        //   createTime: "2023-03-15 14:32:52",
        //   isRead: 1,
        //   message: "3333",
        //   messageId: null,
        //   receiveUserId: null,
        //   sendUserId: null,
        //   userAvatarUrl: "https://tanoshi.oss-cn-hangzhou.aliyuncs.com/coox/portrait/%E9%BB%98%E8%AE%A4%E5%A4%B4%E5%83%8F.jpg",
        //   userId: "1612358004322881538",
        //   userName: "厂家001",
        // },
        // {
        //   createTime: "2023-03-15 10:51:17",
        //   isRead: 1,
        //   message: "123",
        //   messageId: null,
        //   receiveUserId: null,
        //   sendUserId: null,
        //   userAvatarUrl: "https://tanoshi.oss-cn-hangzhou.aliyuncs.com/coox/portrait/%E9%BB%98%E8%AE%A4%E5%A4%B4%E5%83%8F.jpg",
        //   userId: "1628313891701039106",
        //   userName: "厂家管理员",
        // }
      ],
      params: {
        userId: this.$store.state.user.userInfo.userId,
        pageNum: 1,
        pageSize: 20,
      },
      total: 0,
      chatUserId: '',
      chatUserAvatar: '',
      socket: null,
    }
  },
  watch: {
    visible: {
      handler: function(newVal){
        if(newVal){
          this.socket = new WebsocketTask(this.WS_URL)
          this.socket.message(this.onMessage)
        }else{
          this.socket && this.socket.close()
        }
      },
      immediate: true,
    },
  },
  mounted(){
    this.loadNumeList()
  },
  beforeDestroy(){
    this.socket && this.socket.close()
  },
  methods: {
    handleSend(msg){
      const messageData = {
        message: msg,
        sendUserId: this.$store.state.user.userInfo.userId,
        receiveUserId: this.chatUserId,
        // receiveUserId: '1616272731189129218',
      }
      this.socket.send(messageData)
    },
    onMessage(data){
      // console.log('data:', data)
      // console.log('chatUserId:', this.chatUserId)
      const {message, messageId, receiveUserId, sendUserId} = data
      if(sendUserId == this.$store.state.user.userInfo.userId){  // 是当前发出的消息返回 
        // console.log('当前用户发送的消息')
        this.$refs.messageList.pushChat(data)
      }else{ // 是用户发出的消息返回
        if(sendUserId == this.chatUserId){ //聊天内容是当前聊天对象用户
          // console.log('是当前用户的聊天内容')
          this.$refs.messageList.pushChat(data)
        }else{
          // console.log('不是当前用户的聊天内容')
          const hasUser = this.userList.find(item => item.userId === sendUserId)
          // console.log('hasUser:', hasUser)
          if(!hasUser?.userId){  // 聊天内容的用户 没有在用户列表中  否则用户发来的聊天内容的用户 在用户列表中
            // const user = {
            //   userId: '1616272731189129218',
            //   userName: '用户161627273',
            //   isRead: '',
            //   message: '',
            //   receiveUserId: '',
            //   sendUserId: '',
            //   userAvatarUrl: '',
            // }
            // this.userList.unshift(user)
            this.loadNumeList()
          }
        }
      }
    },
    onMessage_old(evt){
      this.$refs.messageList.pushChat(evt)
    },
    async loadNumeList(){
      try{
        const params = {
          ...this.params,
        }
        const res = await chatUserList(params)
        // this.userList = this.userList.concat(res.data.rows)
        if(!this.chatUserId && res.data.rows.length){
          this.chatUserId = res.data.rows[0].userId
          this.chatUserAvatar = res.data.rows.userAvatarUrl
        }
        this.userList = res.data.rows
        this.total = Number(res.data.total)
      }catch(e){

      }
    },
    
    onClose(){
      this.$emit('update:visible', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.dialogBox{
  width: 400px;
  height: 510px;
  border-radius: 10px;
  position: fixed;
  bottom: 30px;
  right: 75px;
  z-index: 10;
  // border: 1px solid red;
  box-shadow: 0px 8px 20px 0px rgb(0 0 0 / 10%);
  overflow: hidden;
  &_top{
    height: 40px;
    background: rgb(85, 114, 244);
    border-radius: 10px 10px 0 0;
    color: white;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    &_icon{
      width: 30px;
      height: 30px;
      // border: 1px solid red;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover{
        cursor: pointer;
      }
    }
  }
  .container{
    // background-color: #fff;
    display: flex;
    .name-list{
      width: 25%;
    }
    .message-list{
      width: 75%;
    }
  }
}
</style>