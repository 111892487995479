<template>
  <div class="nameList">
    <el-scrollbar :noresize="false" :native="false">
      <div class="list">
        <div 
          v-for="(item,index) of list" 
          :key="index" class="list_item" 
          @click="onClickUser(item)"
          :class="{'active': itemId == item.userId}">
            <img :src="item.userAvatarUrl" alt="" style="width: 20px;height:20px;">
            <div class="list_item_name"> {{item.userName}} </div>
            <!-- <span v-if="item.isRead != 1" class="list_item_dot"></span> -->
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
export default {
  name: 'nameList',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    itemId: {
      type: String,
      default: '',
    },
    avatar: {
      type: String,
      default: '',
    },
  },
  methods: {
    onClickUser(item){
      this.$emit('update:itemId', item.userId)
      this.$emit('update:avatar', item.userAvatarUrl)
    },
  },
}
</script>

<style lang="scss" scoped>
.nameList{
  // background: #f4f4f4;  // #f2f2f2;  #edf1fa  #f4f4f4;   #fafafa
  // background-color: #2e3238;  // 二
  background: #F0F8FF;//#F8F8FF;////#F0FFFF;//#F0FFF0;//#FFFAFA;
  height: 470px;
  border-right: 1px solid #edf1fa;
  box-sizing: border-box;
  .list{
    // margin: 0 0 0 5px;
    overflow: hidden;
    &_item{
      padding: 0 5px;
      height: 45px;
      display: flex;
      align-items: center;
      border-bottom: .5px solid #f0f0f0;
      // border-bottom: 1px solid #292C33;  // 二
      cursor: pointer;
      transition: background-color 0.1s;
      position: relative;
      &_name{
        font-size: 14px;
        // color: #f4f4f4;   // 二
        display: inline-block;
        margin-left: 5px;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
      }
      &_dot{
        width: 4px;
        height: 4px;
        background-color: #E94709;
        border-radius: 50%;
        margin-right: 20rpx;
        position: absolute;
        right: 5px;
        opacity: .6;
      }
      &.active{
        background-color: rgba(255, 255, 255, 1) !important;
      }
      &:hover {
        // background-color: rgba(255, 255, 255, 0.03);
        background-color: rgba(255, 255, 255, 0.5);
      }
    }
  }
}
</style>