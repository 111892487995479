import request from '@/plugins/request'


//聊天用户列表
export function chatUserList(params = {}){
  return request({
    url: '/api/user/message/queryChatRecordUserList',
    method: 'GET',
    params: params
  })
}

//聊天记录
export function chatRecord(params = {}){
  return request({
    url: '/api/user/message/queryChatRecord',
    method: 'GET',
    params: params
  })
}



export default{
  chatUserList,
}