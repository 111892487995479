// 顶栏菜单组件
<template>
    <div class="top-menu">
        <!-- LOGO -->
        <div class="top-menu__logo">
            <!-- <a class="logo" href="" title="web" target="_blank">
                <i class="iconlogo_new" />
                <img src="../assets/logo.png" alt="" style="width:26px;height:26px;">
            </a>
            <div class="title"> {{title}} </div> -->
            <img src="../assets/web-logo.png" alt="" title="logo" style="margin-left:20px;">
        </div>

        <!-- 顶部菜单 -->
        <div class="top-menu__menu">
            <!--<div class="menu-item">-->
            <!--<router-link class="menu-item__text" :to="{path:'/main/index'}">总览</router-link>-->
            <!--</div>-->
            <div class="menu-item" v-for="(item, index) in menu" :key="index">
                <!-- 跳转外部链接 -->
                <a class="menu-item__text" target="_blank"
                    :href="item.path|addTokenToUrl"
                    v-if="item.menuLinkType === 'EXTERNAL'">{{ item.name }}</a>
                <!-- 路由 或 iframe -->
                <!-- <router-link class="menu-item__text"
                    :to="{path: item.path ? item.path : 'none'}"
                    v-if="item.menuLinkType !== 'EXTERNAL'">{{ item.name }}
                </router-link> -->
            </div>
        </div>

        <!-- 用户信息 -->
        <div class="top-menu__user">
            
            <!-- 下拉 -->
            <div class="info">
                <!-- @mouseover="infoCardShow = true"   @mouseover="handleInfoCardShow"-->
                <div class="info-name" :key="userInfo.userName"
                    @mouseover="infoCardShow = true"
                    @mouseleave="infoCardShow = false">
                    <i class="icon-user el-icon-user-solid"></i>
                    <span class="name"
                        :title="userInfo.name">{{ userInfo.userName ? userInfo.userName : '默认用户' }}</span>
                    <span>
                        <i class="el-icon-caret-bottom"></i>
                    </span>
                </div>
            </div>

            <!-- 消息按钮 -->
            <!-- <div class="icon-btn">
                <el-badge :value="200" :max="99" class="message">
                    <i class="el-icon-message-solid"></i>
                </el-badge>
            </div> -->

            <!-- 提示按钮 -->
            <!-- <div class="icon-btn">
                <i class="el-icon-s-flag"></i>
            </div> -->
        </div>

        <el-collapse-transition>
            <div class="info-card" v-show="infoCardShow"
                @mouseover="infoCardShow = true"
                @mouseleave="infoCardShow = false">
                <div class="info-card__item">
                    <router-link class="info-card__item__link" :to="{path:'/main/update'}"> 修改密码 </router-link>
                </div>
                <div class="info-card__item logout" @click="logout">退出</div>
            </div>
        </el-collapse-transition>
    </div>
</template>

<script>
// 2.节流
function _throttle(fn , delay = 500) {
  let last = 0  //上传触发时间
  return function (...args){
    const now = Date.now()
    if(now - last > delay){
      last = now
      console.error('_throttle')
      fn.apply(this, args)
    }
  }
}
var that
export default {
    name: 'top-menu',
    props: ['menu'],
    data() {
        return {
            infoCardShow: false,
            accessToken: '',
            title: '喜搭档',
            setConfirm: {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            },
        }
    },
    beforeCreate: function () {
        that = this
    },
    filters: {
        addTokenToUrl: function (val) {
            return val + '?accessToken=' + that.accessToken
        },
    },
    computed:{
        userInfo(){
            return this.$store.state.user.userInfo
        }
    },
    methods: {
        // 退出登录
        logout() {
            this.$confirm('此操作将退出登录, 是否继续?', '提示', this.setConfirm).then(async() => {
                // 调用接口通知平台登出
                const isRequest = false
                const res = await this.$store.dispatch('user/logout', isRequest)
                this.$router.push('/login')
                location.reload()
            })
            .catch(() => { })
        },
        // 初始化用户信息
        loadUserInfo() {
            getUserInfo().then(res => {
                console.log("获取用户信息:",res)
                let userInfo = res.data

            }).catch(err => {
                console.error("err:",err)
            })
        },
        handleInfoCardShow(){
            this.infoCardShow = true 
            if(!this.$store.state.isLoading) return
            this.handleLoadingZindex()
        },
        handleLoadingZindex: _throttle(() => {
            that.$nextTick(() => {
                //方法1
                // let loadingNode = document.getElementsByClassName('el-loading-mask')
                // for (let i = 0; i < loadingNode.length; i++) {
                //     loadingNode[i].style.zIndex = 100
                // }
                //方法2
                let BOX = document.querySelector(".el-loading-mask")
                console.log('BOX', BOX)
                BOX.style.zIndex = "99";//固定一个最大值，是字符串 
            })
        }, 100),
    },
    mounted() {
        // this.accessToken = this.$cookies.token()
        
    },
}
</script>

<style lang="less">
.top-menu {
    .el-badge__content {
        top: 14px;
        z-index: 5;
        padding: 0 4px;
        border: 0;
    }
}
</style>

<style lang="less" scoped>
.top-menu {
    position: relative;
    display: flex;
    width: 100%;
    height: 50px;
    color: #fff;
    // background: #1D1F24;
    background: #2f4050;
    border-bottom: 1px solid #444;

    &__logo {
        display: flex;
        width: 200px;
        //修改
        align-items: center;
        box-sizing: border-box;
        // background: #1D1F24;
        background: #2f4050;
        // border-bottom: .5px solid #888;
        // border-bottom: 1px solid #444;

        .logo {
            // padding: 0 10px;
            text-decoration: none;
            margin-left: 22px;

            // .iconfont {
            //     color: #fff;
            //     font-size: 30px;
            //     line-height: 50px;
            // }
            
        }
        .title {
            padding-left: 10px;
            font-size: 18px;
            color: #fff;
            line-height: 50px;
        }
    }
    &__menu {
        display: flex;
        flex: 1;
        box-sizing: border-box;

        .menu-item {
            padding: 0 20px;
            cursor: pointer;

            &:last-child {
                padding-right: 0;
            }
            &__text {
                display: block;
                height: 50px;
                line-height: 50px;
                font-size: 14px;
                color: #bbb;
                text-decoration: none;
                box-sizing: border-box;

                &:hover {
                    color: #3d91ff;
                }
            }
            .router-link-active {
                color: #3d91ff;
            }
        }
    }
    &__user {
        align-self: center;
        display: flex;
        height: 14px;
        font-size: 12px;
        color: #bbb;
        margin-right: 30px;

        .info {
            padding-right: 10px;
            box-sizing: border-box;

            .info-name {
                cursor: pointer;
                // display: flex;
                // align-items: center;
                .icon-user {
                    padding: 0 8px;
                    fill: #888;
                    // display: inline-block;
                    // padding-top: -5px;
                    // vertical-align: middle;
                    position: relative;
                    top: -5px;
                }
                .name {
                    display: inline-block;
                    max-width: 80px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .el-icon-caret-bottom {
                    vertical-align: top;
                }
                &:hover {
                    color: #3d91ff;

                    .icon-user {
                        fill: #3d91ff !important;
                    }
                }
            }
        }
        .icon-btn {
            position: relative;
            top: -18px;
            padding: 0 15px;
            height: 50px;
            cursor: pointer;

            .icon-inner {
                position: relative;
                top: 16px;
            }
            &:hover {
                background: #404040;
            }
        }
        .logout {
            width: 70px;
            padding: 0 20px;
            box-sizing: border-box;
            cursor: pointer;

            &:hover {
                color: #fff;
            }
        }
    }
    .info-card {
        position: absolute;
        right: 30px;
        top: 50px;
        // width: 180px;
        width: 110px;
        // height: 134px;
        box-sizing: border-box;
        // background: #1D1F24;
        background: #2f4050;
        z-index: 3000 !important;

        &__item {
            padding: 0 10px;
            height: 32px;
            font-size: 12px;
            color: #bbb;
            line-height: 32px;
            cursor: pointer;

            &:hover {
                color: #3d91ff;
            }
            .icon-btn {
                position: relative;
                top: 5px;
                padding: 0 8px;
                fill: #888;
            }

            &:first-child {
                padding-top: 10px;
            }
            &__link {
                // line-height: 32px;
                line-height: 22px;
                font-size: 12px;
                color: #bbb;
                text-decoration: none;
                box-sizing: border-box;
                padding-left: 10px;
                &:hover {
                    color: #3d91ff;
                }
            }

            .router-link-active {
                color: #3d91ff;
            }
        }
        .logout {
            padding-left: 20px;
            padding-bottom: 10px;
        }
        &__line {
            margin-top: 10px;
            padding-top: 10px;
            border-top: 1px solid #515151;
        }
    }
}

</style>
