<template>
  <div class="msgLoading">
    <i class="icon-style el-icon-loading"></i>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.msgLoading{
  text-align: center;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255, .8);
  opacity: .8;
  // .icon-style {
  //   color: #006eff;
  // }
}
</style>