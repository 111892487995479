// @侧栏菜单组件
<template>
    <div class="left-menu">
        <!-- 二级菜单 -->
        <el-scrollbar :native="false" :noresize="false" tag="section"
            style="height: 100%;">

            <el-menu class="el-menu-vertical-demo" menu-trigger="click"
            :default-openeds="openeds" @open="handleOpen" @select="handleActive"
                
                :default-active="$route.path" :router="true"
                :unique-opened="true" background-color="#2f4050" text-color="#BBB"
                :collapse="isCollapse">
                <template v-for="item in menuList">
                    <!-- 分类菜单 -->
                    <el-submenu :index="item.id" :key="item.id" 
                        v-if="item.children && item.children.length">
                        <!-- 标题 -->
                        <template slot="title">
                            <!-- <i class="iconfont icon-style" :class="item.icon"></i> -->
                            <i class="icon-style" :class="item.icon"></i>
                            <span>{{ item.name }} </span>
                        </template>
                        <!-- 路由 -->
                        <!-- :index="el.path" -->
                        <!-- 原来路由-: :route="{path:el.path,query:{title:el.name}}" :index="el.parentId" -->
                        <el-menu-item v-for="el in item.children"
                            :class="{'route-active':$route.path.indexOf(el.path) > -1}"
                            :index="el.path" :key="el.id"
                            >
                            <span slot="title" class="submenu-item-link">
                                <div class="dot"></div>{{ el.name }}
                            </span>
                        </el-menu-item>
                    </el-submenu>

                    <!-- 不分类菜单  v-if="!item.children" v-if="!item.islast"-->
                    <el-menu-item
                        :class="{'route-active':$route.path.indexOf(item.path) > -1}"
                        :index="item.path"
                        :route="{path:item.path,query:{title:item.name}}"
                        :key="item.id" v-else>
                        <!-- <i class="iconfont icon-style" :class="item.icon" /> -->
                        <i class="icon-style" :class="item.icon"></i>
                        <span slot="title" class="menu-item-link">{{ item.name }} </span>
                    </el-menu-item>
                </template>
            </el-menu>
        </el-scrollbar>

        <!-- 伸缩按钮 -->
        <div class="bottom">
            <div class="hide-btn" @click="isCollapse=!isCollapse;">
                <i :class="hideArrow" />
            </div>
        </div>

    </div>
</template>

<script>
import { SStorage } from '@/utils/storage'
export default {
    name: 'left-menu',
    data() {
        return {
            storageOpenId: 'menuOpenId',
            isCollapse: false,
            // openId: localStorage.getItem('menuOpenId') || '',
            openId: SStorage.get(this.storageOpenId) || '',
            // openeds: ['3'],  //'3'
            // openeds: localStorage.getItem('menuOpeneds') || [],  //'3'
        }
    },
    computed: {
        menuList: function () {
            return this.$store.state.user.menuList
        },
        hideArrow: function () {
            if (this.isCollapse) {
                return 'el-icon-s-unfold'
            } else {
                return 'el-icon-s-fold'
            }
        },
        openeds: function(){
            let openId = SStorage.get(this.storageOpenId)
            return [openId]
        },
    },
    watch: {
        isCollapse: {
            handler: function (newVal) {
                this.$emit('isCollapseChange', newVal)
            },
            immediate: false,
        },
        // $route(to, from) {
        //     // this.changeTitle()
        // },
        $route: {
            handler: function(to, form) {
                if(to.meta?.buttonList){
                    console.error('我有buttonList:', to.meta.buttonList)
                    // this.$store.state.user.buttonList = to.meta.buttonList
                }
            },
            immediate: true,
        }
    },

    methods: {
        handleActive(indexPath){
            // console.error('indexPath:',indexPath)  //点击的当前路由:
            // localStorage.setItem('menuOpenId', this.openId)
        },
        handleOpen(index){
            console.log("open:",index)
            this.openId = index
            SStorage.set(this.storageOpenId, index)
        },
        changeTitle() {
            let path = this.$route.path,
            title = '',
            list = this.menuList;
            if (!this.$route.query.title && path.includes('/index')) {
                for(let i of list){
                    if(i.islast){
                        if(title===''){
                            if(path.includes(i.path)){
                                title=i.name;
                            }
                        }
                        else{
                            break;
                        }
                    }
                    else{
                        if(i.children.length>0){
                            for(let j of i.children){
                                if(title===''){
                                    if(path.includes(j.path)){
                                        title=j.name;
                                    }
                                }
                                else{
                                    break;
                                }
                            }
                        }
                        else{
                            break;
                        }
                    }
                }
                if(title){
                    this.$router.replace({path:path,query:{title:title}});
                }
                // console.log(title)
            }
        },
        mounted() {
            // this.changeTitle()
            console.error('menuList',this.menuList)
        }
    }
}
</script>

<style lang="less">
.el-menu--popup {
    border-radius: 0;

    .el-menu-item {
        height: 34px;
        line-height: 34px;

        &:hover {
            background: #444;
        }
    }
    .route-active {
        color: #006eff !important;
    }
}
.left-menu {
    .el-menu,
    .el-menu--vertical {
        text-align: left;
        border-right: 0;
        .el-submenu {
            .el-submenu__title {
                height: 50px;
                line-height: 50px;
            }
            .el-menu-item {
                height: 46px;
                line-height: 46px;
                margin-left: 5px;
            }
            .el-submenu__title,
            .el-menu-item {
                &:hover {
                    background: #444 !important;
                }
            }
            &.is-opened {
                .el-submenu__title,
                .el-menu-item {
                    // background: #262626 !important;
                    background: #2f4050 !important;
                    &:hover {
                        // background: #444 !important;
                        background: #434A50 !important;
                    }
                }
            }
        }
        .el-menu-item {
            height: 50px;
            line-height: 50px;
            &:hover {
                // background: #444 !important;
                background: #434A50 !important;
            }
        }
    }
}
</style>

<style lang="less" scoped>
.left-menu {
    position: relative;
    padding-top: 10px;
    height: 100%;
    // background: #1D1F24;
    background: #2f4050;
    box-sizing: border-box;

    .el-menu-vertical-demo:not(.el-menu--collapse) {
        width: 220px;
        min-height: 400px;
        max-height: calc(100%-56px);
    }
    .bottom {
        position: absolute;
        bottom: 0;
        z-index: 5;
        height: 66px;
        width: 100%;
        // background: #1D1F24;
        background: #2f4050;
        border-top: 1px solid #262626;

        .hide-btn {
            margin: 10px 0 0 10.4px;
            display: inline-block;
            width: 36px;
            height: 36px;
            color: #bbb;
            font-size: 18px;
            line-height: 36px;
            text-align: center;
            box-sizing: border-box;
            border-radius: 100%;
            background: transparent;
            cursor: pointer;

            &:hover {
                background: #555151;
            }
        }
    }
    .route-active {
        color: #006eff !important;

        .icon-style {
            color: #006eff;
        }
        .dot {
            background: #006eff;
        }
    }
    .submenu-item-link {
        font-size: 12px;
    }
    .menu-item-link {
        font-size: 14px;
    }
    .icon-style {
        display: inline-block;
        width: 32px;
        padding-right: 10px;
        font-size: 22px;
        box-sizing: border-box;
    }
    .dot {
        position: relative;
        left: -16px;
        top: -2px;
        display: inline-block;
        margin-left: 4px;
        width: 4px;
        height: 4px;
        background: #bbb;
        border-radius: 100%;

        // margin-right: 5px;
    }
}
.left-menu .el-menu-vertical-demo[data-v-3d6a1105]:not(.el-menu--collapse) {
    width: 220px;
    min-height: 400px;
    max-height: calc(100%-56px);
    padding-bottom: 85px;
}
</style>
