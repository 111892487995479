// @主面板
<template>
    <div class="home">
        <!-- 顶部菜单 -->
        <div class="home__top">
            <top-menu :menu="mainMenu"></top-menu>
        </div>

        <!-- 带侧栏 -->
        <div class="home__wrap">
            <!-- 侧栏菜单 -->
            <div class="left-container" v-if="menuList && menuList.length>0">
                <div class="left-container__inner">
                    <left-menu @isCollapseChange="handleIsCollapseChange"></left-menu>
                </div>
            </div>

            <!-- 内容面板 -->
            <div class="container" ref="container">
                <div class="container__inner"
                    v-loading="$store.state.isLoading" 
                    element-loading-text="加载中..." 
                    element-loading-spinner="el-icon-loading" 
                    element-loading-background="rgba(255, 255, 255, 0.8)"
                    :class="{'isCollapse' : isCollapse, 'isNoLeft': !menuList || menuList.length<=0}">
                    <el-scrollbar :noresize="false" :native="false">
                        <router-view class="container__inner__view"></router-view>
                    </el-scrollbar>
                </div>
            </div>
        </div>
        
        <web-chat></web-chat>
        <!-- <chatDialog/> -->
        <!-- <JwChatDialog /> -->
    </div>
</template>

<script>
import TopMenu from '@/components/top-menu.vue' // 顶部菜单
import LeftMenu from '@/components/left-menu.vue' // 侧栏菜单
import webChat from './chat/index.vue'

// import chatDialog from './chatDialog.vue'
import JwChatDialog from './JwChat/JwChatDialog.vue'
export default {
    name: 'home',
    components: { TopMenu, LeftMenu, webChat,
        // chatDialog,
        JwChatDialog,
    },
    data() {
        return {
            isCollapse: false,
        }
    },
    computed: {
        mainMenu: function () {
            return this.$store.state.mainMenu
        },
        menuList: function () {
            return this.$store.state.user.menuList
        }
    },
    // watch: {
    //     $route(to, form){
    //         this.$store.state.isLoading = false
    //     }
    // },
    methods: {
        // 侧栏菜单展开/收起
        handleIsCollapseChange(isCollapse) {
            this.isCollapse = isCollapse
        },
    },
}
</script>

<style lang="less" scoped>
.service-chat{
    position: fixed;
    right: 20px;
    bottom: 30px;
    z-index: 50;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #409EFF;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover{
        cursor:pointer;
    }
    &__icon{
        width: 26px;
        height: 26px;
        background-image: url('~@/assets/serve_icon.png');
        background-repeat: no-repeat;
        background-size: 100%;
    }
}
.home {
    position: relative;
    height: 100vh;
    // min-width: 1220px;

    &__top {
        position: absolute;
        z-index: 1000;
        width: 100%;
        // min-width: 1120px;
    }
    &__wrap {
        position: relative;
        width: 100%;

        .left-container {
            position: absolute;
            left: 0;
            z-index: 2;
            display: inline-block;
            vertical-align: bottom;
            padding-top: 50px;
            height: 100vh;
            background: #333;
            box-sizing: border-box;

            &__inner {
                height: 100%;
            }
        }
        .container {
            vertical-align: bottom;
            padding-top: 50px;
            height: 100vh;
            box-sizing: border-box;
            background: #edf1fa;// #f2f2f2;  #edf1fa
            z-index: 3;  // 修改----------增加 调试欧朋浏览器

            &__inner {
                height: 100%;
                margin-left: 220px;
                transition: 0.3s all ease-in-out;
                // overflow: auto;
                &.isCollapse {
                    margin-left: 64px;
                }
                &.isNoLeft {
                    margin-left: 0;
                }
                &__view {
                    height: 100%;
                    box-sizing: border-box;
                    
                }
            }
        }
    }
}
</style>

<style lang="less">

.home{
    // 滚动条
    .el-scrollbar {
        height: 100%;
    }

    .el-scrollbar__wrap {
        overflow: scroll;
        overflow-x: auto;
    }
}


</style>