<template>
  <div class="jw-chat-dialog">
    <div class="service-chat" @click="showDialog = !showDialog">
        <div class="service-chat__icon"></div>
    </div>
    <div class="dialog" v-if="showDialog">
      <JwChat />
    </div>
  </div>
</template>

<script>
import JwChat from './JwChat.vue'
export default {
  name: 'JwChatDialog',
  components: {JwChat},
  data(){
    return{
      showDialog: false,
    }
  },
}
</script>

<style lang="less" scoped>
.service-chat{
  position: fixed;
  right: 20px;
  bottom: 30px;
  z-index: 50;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #409EFF;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover{
    cursor:pointer;
  }
  &:active{
    opacity: .8;
  }
  &__icon{
    width: 26px;
    height: 26px;
    background-image: url('~@/assets/serve_icon.png');
    background-repeat: no-repeat;
    background-size: 100%;
  }
}
</style>