<template>
  <div class="uploadBtn">
    <!-- list-type="picture" -->
    <el-upload
      class="upload-demo"
      action="https://jsonplaceholder.typicode.com/posts/"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :http-request="httpRequest"
      :file-list="fileList"
      >
      <!-- <el-button size="mini" type="text">点击上传</el-button> -->
      <slot v-if="$slots.default" />
    </el-upload>
  </div>
</template>

<script>
import {upload} from '@/api/user'
export default {
  name: 'uploadImage',
  props: {
    value: {
      type: String,
      default: '',
    },
    type: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 1
    },
  },
  data(){
    return{
      fileList: [],
      urlList: [],
    }
  },
  methods: {
    async httpRequest(e){
      // return this.$refs.upload.submit()
      const file = e.file
      const form = new FormData()
      form.append('file', file)
      form.append('type', this.type)
      upload(form).then(res => {
        if(res.code == 200){
          this.urlList.push(res.data)
          const value = res.data
          this.$emit('input', value)
          this.$emit('change', value)
          e.onSuccess(res)
        }else{
          e.onError(res)
        }
      })
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
  },
}
</script>

<style lang="scss" scoped>
.uploadBtn{
  display: flex;
  align-items: center;
}
</style>

<style lang="scss">
.uploadBtn{
  .el-upload-list{
    display: none;
  }
  // .el-upload:active{
  //   color: red;
  // }
  // .el-upload:visited{
  //   color: red;
  // }
}
</style>