<template>
<div class="session-right">
  <div class="messageList">
    <!-- v-scroll-bottom="currentSession.messages" -->
    <el-scrollbar :noresize="false" :native="false" class="message" ref="myScrollbar">
      <msgLoading  v-if="loading"/>
      <ul v-if="itemId">
        <li v-for="(item,index) in chatList" :key="item.messageId || index">
          <p class="time" v-if="item.createTime && Number.isInteger(index/5)">
            <span>{{ item.createTime.slice(5,16) }}</span>
            <!-- <span>{{ item.createTime | time }}</span> -->
          </p>
          <div class="main" :class="{ self: item.sendUserId == currentUserId }">
            <img class="avatar" width="20" height="20" :src="item.sendUserId == currentUserId ? userPhoto : avatar" />
            <div class="text">{{ item.message }}</div>
          </div>
        </li>
      </ul>
    </el-scrollbar>
  </div>
  <div class="message-bottom">
    <!-- <div style="height:20px;padding:0 10px;">
      <span style="cursor:pointer;">
        <upload-image :limit="1" @change="change">
          <i class="iconfont icon-PDF-red" style="color:#cfcfcf;"></i>
        </upload-image>
      </span>
    </div> -->
    <textarea placeholder="按 Ctrl + Enter 发送" v-model="content" @keyup="onKeyup"></textarea>
    <span class="send-btn" @click="sendMessage">发送</span>
  </div>
</div>
</template>

<script>
import uploadImage from './uploadImage.vue'
import msgLoading from './msgLoading.vue'
import { debounce } from '@/utils'
import {chatRecord} from '@/api/chat'

export default {
  name: 'messageList',
  components: {uploadImage, msgLoading},
  props: {
    itemId: {
      type: String,
      default: '',
    },
    avatar: {
      type: String,
      default: 'https://tanoshi.oss-cn-hangzhou.aliyuncs.com/coox/portrait/%E9%BB%98%E8%AE%A4%E5%A4%B4%E5%83%8F.jpg',
    },
  },
  data(){
    return{
      content: '',
      params: {
        userId: this.$store.state.user.userInfo.userId,
        toUserId: '',
        pageNum: 1,
        pageSize: 20,
      },
      total: 0,
      chatList: [],
      leftUrl: '',  //左边用户聊天头像
      loading: false,
    }
  },
  computed: {
    userPhoto(){
      return this.$store.state.user.userInfo.avatarUrl // 'https://vitejs.cn/logo.svg' 
    },
    currentUserId(){
      return this.$store.state.user.userInfo.userId
    },
  },
  watch: {
    itemId: {
      handler: function(newVal){
        newVal && this.reloadChat()
      },
      deep: true,
    },
    '$store.state.service.showDialog': {
      handler: function(newVal){
        if(newVal && this.chatList.length){
          this.scrollDown()
        }
      }
    },
  },
  mounted(){
    this.$refs.myScrollbar.wrap.addEventListener("scroll", debounce(this.scrollChange, 300))
  },
  // updated(){}
  beforeDestroy () {
    this.$refs.myScrollbar.wrap.removeEventListener('scroll', this.scrollChange)
  },
  methods: {
    pushChat(chat){
      // this.chatList = this.chatList.concat([chat])
      this.chatList.push(chat)
      this.content = ''
      setTimeout(() => {
        this.scrollDown()
      }, 10)
    },
    reloadChat(){
      this.chatList = []
      this.params.pageNum = 1
      this.loadChat()
    },
    async loadChat(){
      this.scrollHeight = this.$refs['myScrollbar'] ? this.$refs['myScrollbar'].wrap.scrollHeight : 0
      this.loading = true
      try{
        this.params.toUserId = this.itemId
        const res = await chatRecord(this.params)
        this.total = Number(res.data.total)

        const historyList = res.data.rows
        const chatList = historyList.concat(this.chatList)
        this.chatList = chatList

        // console.log('chatList:', this.chatList)
        
        //设置滚动条位置
        if(this.params.pageNum == 1){
          // 首次渲染后获取scrollHeight并滑动到底部。
          setTimeout(() => {
            // let currScrollHeight = this.$refs['myScrollbar'].wrap.scrollHeight
            // this.$refs['myScrollbar'].wrap.scrollTop=currScrollHeight
            this.scrollDown()
          },20)
        }else{
          // 滚动到加载前的位置
          setTimeout(() => {
            let currScrollHeight = this.$refs['myScrollbar'].wrap.scrollHeight
            this.$refs['myScrollbar'].wrap.scrollTop=currScrollHeight - this.scrollHeight
          }, 20)
        }
        this.loading = false
      }catch(e){
        this.loading = false
      }
    },
    scrollChange(e){
      const scrollTop = e.target.scrollTop
      console.log('scrollTop:', scrollTop)
      
      if(scrollTop < 5){
        // console.error('scrollHeight:', this.$refs['myScrollbar'].wrap.scrollHeight)
        // const oldScrollHeight = this.$refs['myScrollbar'].wrap.scrollHeight

        //已滚动到最顶部: 加载聊天记录
        if(this.chatList.length >= this.total) return
        this.params.pageNum += 1
        this.loadChat()

      }
    },
    scrollDown(){  // 设置聊天内容滚动在最底部
      this.$refs['myScrollbar'].wrap.scrollTop = this.$refs['myScrollbar'].wrap.scrollHeight

      //设置滚动条位置
      // if(this.params.pageNum == 1){
      //   // 首次渲染后获取scrollHeight并滑动到底部。
      //   setTimeout(() => {
      //     // let currScrollHeight = this.$refs['myScrollbar'].wrap.scrollHeight
      //     // this.$refs['myScrollbar'].wrap.scrollTop=currScrollHeight
      //   },20)
      // }else{
      //   // 滚动到加载前的位置
      //   setTimeout(() => {
      //     let currScrollHeight = this.$refs['myScrollbar'].wrap.scrollHeight
      //     this.$refs['myScrollbar'].wrap.scrollTop=currScrollHeight - this.scrollHeight
      //   }, 20)
      // }
    },
    change(e){
      console.log('url:', e)
      this.content = this.content + e
      this.sendMessage()
    },
    onKeyup(e){
      if (e.ctrlKey && e.keyCode === 13 && this.content.length) {
        this.sendMessage()
      }
    },
    sendMessage(){
      if(!this.content) return
      this.$emit('message', this.content)
    },
  },
  filters: {
    // 将日期过滤为 hour:minutes
    time (date) {
      if (typeof date === 'string') {
          date = new Date(date);
      }else {
        return null
      }
      return date.getHours() + ':' + date.getMinutes();
    }
  },
  directives: {
    // 发送消息后滚动到底部
    'scroll-bottom' () {
      this.$nextTick(() => {
          // this.el.scrollTop = this.el.scrollHeight - this.el.clientHeight;
          // this.$el.scrollTop = this.$el.scrollHeight - this.$el.clientHeight;
          this.$refs['myScrollbar'].wrap.scrollTop = this.$refs['myScrollbar'].wrap.scrollHeight
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.session-right{
  height: 100%;
  
  .message-bottom{
    height: 110px;
    // border: 1px solid red;
    box-sizing: border-box;
    background-color: #FFFFFF;
    border-top: solid 1px #ddd;
    position: relative;
    textarea {
      font-size: 12px;
      padding: 10px;
      // margin: 10px;
      height: 100%;
      // width: 100%;
      width: calc(100% - 20px);
      border: none;
      outline: none;
      font-family: "Micrsofot Yahei";
      resize: none;
    }
    .send-btn{
      position: absolute;
      bottom: 20px;
      right: 20px;
      cursor:pointer;
      z-index: 50px;

      color: #666666;
      background-color: #F0F1F3;
      line-height: 22px;
      border-radius: 6px;
      padding-left: 10px;
      padding-right: 10px;
      display: inline-block;
    }
  }
}

.messageList{
  height: 360px;
  box-sizing: border-box;
  background-color: #FFF;//#fafafa; // #edf1fa;  #f5f5f5    #fafafa
  // background-color: #edf1fa;//#fafafa; // #edf1fa;  #f5f5f5    #FFFAFA
}

ul,li{
  outline: none;
  // list-style-type: circle;
  // list-style-type: square;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.message {
  // padding: 0px 8px;
  // overflow-y: scroll;
  overflow-x: none;
  li {
    margin-bottom: 1px;
    padding: 10px 10px;
  }
  .time {
    // margin: 7px 0;
    text-align: center;

    > span {
      display: inline-block;
      padding: 0 18px;
      font-size: 12px;
      color: #fff;
      border-radius: 2px;
      background-color: #dcdcdc;
    }
  }
  // .main{
  //   display: flex;
  //   justify-content: space-between;
  // }
  .avatar {
    float: left;
    margin: 0 8px 0 0;
    border-radius: 3px;
  }
  .text {
    display: inline-block;
    position: relative;
    padding: 0 10px;
    // max-width: ~'calc(100% - 40px)';
    max-width: calc(100% - 48px);
    min-height: 20px;
    line-height: 1.9;
    font-size: 12px;
    text-align: left;
    word-break: break-all;
    background-color: #fafafa;
    border-radius: 4px;

    &:before {
      content: " ";
      position: absolute;
      top: 5px;
      right: 100%;
      border: 6px solid transparent;
      border-right-color: #fafafa;
    }
  }

  .self {
    text-align: right;

    .avatar {
      float: right;
      margin: 0 0 0 8px;
    }
    .text {
      background-color: #b2e281;

      &:before {
        right: inherit;
        left: 100%;
        border-right-color: transparent;
        border-left-color: #b2e281;
      }
    }
  }
}
</style>

<style lang="scss">
// 必要的
//隐藏底部滚动条

// /deep/ .el-scrollbar__wrap {
//   overflow: scroll;
//   height: 100% !important;
//   overflow-x: hidden !important;
// }
</style>